<template>
  <div  class="detail_container">
    <div style="margin-bottom: 10px">
      <el-button type="primary" size="mini" @click="capture">
        导出软文
      </el-button>
    </div>
    <div ref="capture">
      <div class="detail_banner">
        <img :src="row.image" />
        <div class="detail_info">
          <div class="detail_title">
            {{ row.name }}
          </div>
        </div>
      </div>
      <div class="detail_text">
        {{ row.content }}
      </div>
    </div>
  </div>
</template>

<script>
import html2canvas from "html2canvas";
export default {
  name: "advertorial",
  props: ["row"],
  data() {
    return {};
  },
  methods: {
    capture() {
      // 使用 ref 获取需要截图的元素
      const element = this.$refs.capture;

      // 使用 html2canvas 截图
      html2canvas(element, { allowTaint: true, useCORS: true }).then(
        (canvas) => {
          // 将 canvas 转换为图片数据
          const imgData = canvas.toDataURL("image/png");
          // 创建一个新的 img 元素来显示截图
          // const img = document.createElement('img');
          // img.src = imgData;

          // 将截图添加到 DOM 中（可选）
          // document.body.appendChild(img);

          // 或者你可以直接下载截图
          const link = document.createElement("a");
          link.href = imgData;
          link.download = this.row.name + ".png";
          link.click();
        }
      );
    },
  },
};
</script>

<style lang='scss' scoped>
.detail_container {
  width: 375px;
  margin: 0 auto;
  line-height: 1.5;
}

/* 统一富文本图片大小 */
.detail_text img {
  width: 100%;
  max-width: 100%;
  object-fit: cover;
}
.detail_banner {
    position: relative;
    width: 100%;
    height: 182.5012px;
}

.detail_banner img {
    width: 100%;
    height: 182.5012px;
    object-fit: cover;
}

.detail_info {
    position: absolute;
    bottom: 19.9988px;
    width: 100%;
    padding: 0 15px;
    box-sizing: border-box;
}

.detail_title {
    font-size: 19.9988px;
    color: #fff;
    font-weight: bold;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
    white-space: nowrap;
    text-shadow: 3px 3px 7px #000000
}

.detail_depict {
    font-size: 13.0013px;
    color: #fff;
    margin-top: 3.75px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.detail_text {
    color: #666666 !important;
    font-size: 15px;
    padding: 0 15px 30px 15px;
    margin-top: 15px;
}
</style>