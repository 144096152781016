<template>
  <div class="detail_container">
    <div style="margin-bottom: 10px">
      <el-button type="primary" size="mini" @click="capture">
        导出海报
      </el-button>
    </div>
    <div class="poster" ref="capture">
      <div class="poster_title">
        {{ row.name }}
      </div>
      <div class="poster_time">
        {{ "( " + row.create_time.slice(0, 10) + " )" }}
      </div>
      <img :src="row.image" alt="" srcset="" class="poster_image" />
      <div class="poster_content">
        {{ row.content }}
      </div>
    </div>
  </div>
</template>

<script>
import html2canvas from "html2canvas";
export default {
  name: "poster",
  props: ["row"],
  methods: {
    capture() {
      // 使用 ref 获取需要截图的元素
      const element = this.$refs.capture;

      // 使用 html2canvas 截图
      html2canvas(element, { allowTaint: true, useCORS: true }).then(
        (canvas) => {
          // 将 canvas 转换为图片数据
          const imgData = canvas.toDataURL("image/png");
          // 创建一个新的 img 元素来显示截图
          // const img = document.createElement('img');
          // img.src = imgData;

          // 将截图添加到 DOM 中（可选）
          // document.body.appendChild(img);

          // 或者你可以直接下载截图
          const link = document.createElement("a");
          link.href = imgData;
          link.download = this.row.name + ".png";
          link.click();
        }
      );
    },
  },
};
</script>

<style lang='scss' scoped>
.detail_container {
  width: 375px;
  margin: 0 auto;
  line-height: 1.5;
}
.poster {
  width: 375px;
  height: 737px;
  background: url("../../../assets/images/poster_bg.png") no-repeat;
  background-size: 100% 100%;
  position: relative;
  .poster_title {
    font-size: 30px;
    font-family: "Microsoft YaHei";
    color: rgb(255, 255, 255);
    line-height: 1.2;
    text-align: center;
    text-shadow: 0px 1.5px 3.5px rgba(0, 0, 0, 0.35);
    position: absolute;
    top: 12%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
  }
  .poster_time {
    font-size: 15px;
    font-family: "Adobe Heiti Std";
    color: #333333;
    line-height: 1.233;
    position: absolute;
    left: 48%;
    font-weight: bold;
    top: 22.8%;
  }
  .poster_time {
    height: 135px;
  }
  .poster_image {
    width: 298px;
    height: 137px;
    border-radius: 7px;
    object-fit: cover;
    position: absolute;
    left: 50%;
    top: 41.58%;
    transform: translate(-50%, -50%);
    margin-left: 4px;
    pointer-events: none;
  }
  .poster_content {
    width: 71%;
    font-size: 12px;
    color: #333333;
    position: absolute;
    left: 50%;
    margin-top: 462px;
    transform: translate(-50%, 0%);
    line-height: 1.5;
    display: -webkit-box;
    -webkit-line-clamp: 10; /* 设置显示的行数 */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>