<template>
  <el-dialog
    :title="
      component === 'poster' ? '一键生成海报素材库' : '一键生成软文素材库'
    "
    :visible.sync="dialogVisible"
    width="430px"
  >
    <poster v-if="component === 'poster'" :row="row"></poster>
    <advertorial v-if="component === 'advertorial'" :row="row"></advertorial>
  </el-dialog>
</template>

<script>
import poster from "./poster.vue";
import advertorial from "./advertorial.vue";
export default {
  data() {
    return {
      dialogVisible: false,
      component: "",
      row: {},
    };
  },
  components: {
    poster,
    advertorial,
  },
  methods: {
    openDialog(componentName, row) {
      this.dialogVisible = true;
      this.component = componentName;
      this.$nextTick(() => {
        this.row = row;
      });
    },
  },
};
</script>

<style lang='scss' scoped>
/deep/.el-dialog__body {
  padding-top: 0;
}
</style>